<template>
  <div>
    <div class="jumbotron-wrapper image" style="height: 400px">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5">
          <div class="h1">开放 合作 共赢</div>
        </div>
      </div>
    </div>

    <div class="content p-auto mb-5">
      <section
        class="container-lg px-4 d-flex flex-column justify-content-center"
        style="min-height: 400px"
      >
        <div class="text-center lh-lg">
          <div class="fs-3 d-none d-lg-block">
            非凸科技正基于Rust 生态，打造业内领先的智能交易系统，为中小投资者保驾护航。
          </div>
          <div class="fs-5 d-lg-none">
            非凸科技正基于Rust 生态，打造业内领先的智能交易系统，为中小投资者保驾护航。
          </div>
        </div>
      </section>
       <section id="business" >
       </section>  
      <section>
        <div class="container-lg px-4 py-3 mt-5">
          <div class="row-container row g-0 overflow-hidden" >
          
            <div class="image">
              <div class="parachute"></div>
            </div>

            <div class="col-6 back back-right" style="background-color: #464440"></div>
            <div class="text text-right position-absolute" style="background-color: #464440">
              <div class="title text-light fw-bold">成为伙伴</div>
              <div class="text-light opacity-75">
                如果您有关于产品的任何问题，请立即联系我们
              </div>
              <div class="text-light opacity-75">商务咨询：182 0217 0437</div>
              <div class="text-light opacity-75">申请试用：153 8016 7930</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-lg px-4 py-3">
          <div class="row-container row g-0 overflow-hidden">
            <div class="text text-left position-absolute">
              <div class="title text-light fw-bold">定制化服务</div>
              <div class="text-light opacity-75">
                为您提供定制化的解决方案及支持
              </div>
              <router-link
                type="button"
                class="mt-3 btn btn-light btn-sm"
                style="color: #72542e"
                to="/product/platform#customization"
              >
                了解更多
              </router-link>
            </div>
            <div
              class="col-6 back back-left"
              style="background-color: #004478"
            ></div>
            <div class="image">
              <div class="customization"></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="container-lg px-4 py-3">
          <div class="row-container row g-0 overflow-hidden">
           <div
              class="col-6 back back-right"
              style="background-color: #8a5e38"
            ></div>
            <div class="image">
              <div class="services"></div>
            </div>

            <div class="col-6 back back-right"></div>
            <div class="text text-right position-absolute">
              <div class="title text-light fw-bold">咨询顾问服务</div>
              <div class="text-light opacity-75">
                一对一讲师培训，全面解答设备、合规、绩效等问题
              </div>
              <div class="text-light opacity-75">联合服务私募客户，进行产品公开路演、售前售后拜访等</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Business",
};
</script>

<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/business_banner.jpg");
  background-position-y: 40%;
}
section {
  min-height: 0;
}
.row-container {
  height: 20rem;
  position: relative;
}
.image > div {
  height: 20rem;
  background-size: contain;
  background-position-y: top;
}
.row-container .parachute {
  background-image: url("../../assets/img/business/parachute.png");
  background-position-x: left;
}
.row-container .customization {
  background-image: url("../../assets/img/business/customization.jpg");
  background-position-x: right;
}
.row-container .shanghai {
  background-image: url("../../assets/img/business/shanghai.jpg");
  background-position-x: left;
}
.row-container .services {
  background-image: url("../../assets/img/business/services.jpg");
  background-position-x: left;
}

.row-container .back {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: skewX(-20deg);
  transition: all 0.5s;
}
.row-container .back-right {
  background-color: #464440;
  right: -45.6989%;
}
.row-container .back-left {
  background-color: #72542e;
  left: -45.6989%;
}
.row-container .back-right {
  background-color: #8a5e38;
  right: -45.6989%;
}
.row-container .text-left {
  z-index: 1;
}
.row-container .text-right {
  z-index: 1;
  left: 50%;
}
.row-container .text {
  margin: 2.5rem;
}
.row-container .text div {
  padding-top: 1rem;
}
.row-container .title {
  letter-spacing: 0.5rem;
  font-size: 2rem;
}

@media screen and (max-width: map-get($container-max-widths,md)) {
  .back {
    left: 0 !important;
    top: 0 !important;
    z-index: -1 !important;
    transform: skew(0) !important;
  }
  .cover-text > div {
    font-size: 2.5rem !important;
    text-align: center;
  }
  .image > div {
    height: 10rem;
  }
  .text {
    bottom: 0;
    height: 8rem;
    left: 0 !important;
    width: 100%;
    width: fit-content;
    margin: 1rem 2.5rem !important;
  }
  .text div {
    padding-top: 0 !important;
  }
  .title {
    letter-spacing: 0 !important;
  }
  .image > div {
    background-size: cover;
  }
}
</style>
